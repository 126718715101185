
.faq_wrapper{
    display: flex;
    width: 1000px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width:1200px) {
        width: 700px;
    }
    @media screen and (max-width:900px) {
        width: 550px;
    }
    @media screen and (max-width:700px) {
        width: 350px;
    }
}

.faq_accordion{
    width: 100%;
}


.faq_item{
    background-color: #707070;
    margin-bottom: 5px;
    padding: 10px 20px;
    border-radius: 5px;
    
}

.faq_title{
    color: rgb(255, 255, 255);
    padding: 0 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq_content{
    
    width: 100%;
    border-radius: 5px;
    padding: 0px 10px;
    // color: white;
    background-color: rgb(254, 255, 241);
    
    max-height: 0;
    overflow: hidden;
    // transition: all  .1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all  .2s cubic-bezier(1, 0.610, 0.355, 0.215);
}

.faq_content.faq_show{
    
    // color: white;
    border-radius: 5px;
    padding: 10px;
    height: auto;
    max-height: 9999px;
    // transition: all  .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all  .2s cubic-bezier(0.215, 0.610, 0.355, 1);
}