.Topbar__container {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 40px;
  // background-color:rgb(6, 60, 160);
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-wrap: nowrap;

  marquee {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    // transform: translateX(100%);
    // animation: move 40s linear infinite;
    letter-spacing: 0.6px;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  // @keyframes move {
  //     to {
  //         transform: translateX(-100%);
  //     }
  // }
}
