.app__footer {
  padding-top: 150px;

  @media screen and (max-width: 1000px) {
    padding-top: 100px;
  }
}

.app__footer-cards {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .app__footer-card {
    min-width: 270px;
    display: flex;
    height: 50px;

    // flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: #393939;
    transition: all 0.3s ease-in-out;

    a {
      color: white;
    }

    svg {
      // width: 40px;
      // height: 40px;
      margin: 0 0.7rem;
      color: rgb(255, 225, 0);
    }

    p {
      font-weight: 500;
    }

    a {
      text-decoration: none;
      font-weight: 500;
    }

    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 25px #f2f7fb;
}

.contact-locations,
.contact-timing {
  display: flex;
  flex-direction: column;

  // gap: 20px;

  padding-bottom: 30px;
  padding-top: 30px;

  align-items: center;

  .contact-items {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    gap: 50px;
    margin-left: 20px;
    margin-right: 20px;

    .contact_items-item {
      width: 400px;
      @media screen and (max-width: 768px) {
        width: 320px;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  img {
    width: 100%;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.03);
    }
  }
}

.contact-timing {
  img {
    width: 340px;
    @media screen and (max-width: 768px) {
      width: 320px;
    }
  }
}

a {
  color: rgb(12, 31, 156);
}
