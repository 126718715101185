.master__courses__header {
  padding-top: 150px;
  color: var(--secondary-color);

  @media screen and (max-width: 1000px) {
    padding-top: 100px;
  }
}

.master__courses-desc {
  display: flex;
  flex-direction: column;
  margin: 20px 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;

  @media screen and (max-width: 1000px) {
    margin: 30px 20px;
  }

  h2 {
    // font-size: 2rem;

    @media screen and (max-width: 600px) {
      // font-size: 1.7rem;
      // text-align: justify;
    }
  }

  // p {
  //     // text-align: justify;
  // }
}

.master__course-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 4rem 0 2rem;

  .master__course__filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--primary-color);
    color: #000;
    font-weight: 800;

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__master__course-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // align-items: center;

  .app__master__course-item {
    position: relative;
    width: 320px;
    // height: 620px;
    flex-direction: column;
    justify-content: flex-start;

    margin: 2rem;
    padding: 1rem;
    padding-bottom: 60px;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;

    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }

    .master__course__enroll-btn {
      position: absolute;
      right: 29%;
      background-color: rgb(6, 60, 160);
      width: 130px;
      height: 50px;
      border: none;
      border-radius: 15px;
      font-size: medium;
      color: #fff;
      cursor: pointer;
      transition: ease-in-out 0.3s;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
      bottom: 10px;

      &:hover {
        scale: 1.03;
      }
    }
  }
}

.app__master__course-img {
  width: 100%;
  height: 230px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
    position: relative;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__master__course-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}

.app__master__course-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    margin-top: 1rem;
    line-height: 1.5;
    font-size: 1.1rem;
    background-color: var(--primary-color);
    width: 230px;
    padding: 5px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    }
  }

  .app__master__course-tag {
    position: absolute;

    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #fff;
    top: -25px;

    @media screen and (max-width: 385px) {
      p {
        font-size: 0.9rem;
      }
    }
  }
}

.master__course__content__container {
  display: flex;
  gap: 10px;

  p {
    font-size: 0.9rem;
    // text-align: justify;
  }
}

.file_upload_masters {
  display: flex;
  justify-content: center;
}
