.singlepost_container {
  display: flex;
  flex-direction: column;
  padding: 150px 150px 0px 150px;
  align-items: center;
  justify-content: center;
  margin: 0 100px;
  gap: 25px;

  @media screen and (max-width: 1400px) {
    padding: 150px 0px;
    padding-bottom: 30px;
    margin: 0 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 130px 0px;
    padding-bottom: 30px;
    margin: 0 30px;
  }
}

.singlepost_image {
  max-width: 800px;
  img {
    width: 100%;
    border-radius: 15px;
  }
}

.singlepost_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.singlepost_desc {
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 30px;
}
