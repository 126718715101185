.popup__container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: popupDelay 0.3s ease-in-out;
  overflow: hidden;
}

@keyframes popupDelay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.popup {
  background-color: #ffffff;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 2px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

  max-width: 90vw;
  max-height: 90vh;

  overflow-y: auto;

  @media screen and (min-width: 1000px) {
    width: 650px;
    height: auto;
  }
  
  @media screen and (max-width: 600px) {
    width: 90%;
    padding: 0 10px 10px 10px;
  }
}

.popup button {
  align-self: flex-end;
  background-color: transparent;
  font-size: 30px;
  color: #333333;
  border: none;
  outline: none;
  cursor: pointer;
}

.popup img {
  width: 100%;
  max-height: 600px;
  object-fit: contain;
  margin-bottom: 15px;
}

.popup a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  text-align: center;
  background-color: #ffd54b;
  color: #000000;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  // margin-top: 0px;
  transition: background-color 0.3s ease-in-out;

  

  &:hover {
    background-color: #ffbb00;
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;
    width: 120px;
    padding: 6px;
  }
}
