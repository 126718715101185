.app__navbar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  background: rgba(255, 225, 0, 1);
  position: fixed;
  top: 40px;
  z-index: 2;

  @media screen and (max-width: 1000px) {
    // top: 0px;
  }
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;

  img {
    width: 260px;
    height: 50px;

    @media screen and (max-width: 768px) {
      width: 200px;
      height: 40px;
    }
  }
}

.app__navbar-links {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(178, 0, 0);

  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
  }

  // div {
  //   position: fixed;
  //   top: 0;
  //   bottom: 0;
  //   right: 0;
  //   z-index: 5;

  //   padding: 1rem;
  //   padding-top: 1.5rem;

  //   width: 80%;
  //   height: 100vh;

  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: flex-end;
  //   flex-direction: column;

  //   background: url('../../assets/bgWhite.png');
  //   background-color: var(--white-color);
  //   background-size: cover;
  //   background-repeat: repeat;
  //   border-radius: 25px 0px 0px 0px;

  //   /* top box shadow */
  //   box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

  //   svg {

  //     width: 35px;
  //     height: 35px;
  //     color: rgb(178, 0, 0);
  //     margin: 0rem 1rem;
  //   }

  // ul {
  //   list-style: none;
  //   margin: 0;
  //   padding: 0;
  //   height: 100%;
  //   width: 100%;

  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: flex-start;
  //   flex-direction: column;

  //   li {
  //     margin: 1rem;

  //     a {
  //       color:  black;
  //       text-decoration: none;
  //       font-size: 1rem;
  //       text-transform: uppercase;
  //       font-weight: 500;

  //       transition: all .3 ease-in-out;

  //       &:hover {
  //         color: var(--secondary-color);
  //       }
  //     }
  //   }
  // }

  @media screen and (min-width: 1000px) {
    display: none;
  }
}

.app_navbar-menu-container {
  position: fixed;
  top: 40px;
  bottom: 0;
  right: 0;
  z-index: 5;

  padding: 1rem;
  padding-top: 1.5rem;
  padding-left: 2rem;

  width: 80%;
  height: 100vh;

  // display: flex;
  // justify-content: space-between;
  // align-items:center;
  // flex-direction: column;

  background: url("../../assets/bgWhite.png");
  background-color: var(--white-color);
  background-size: cover;
  background-repeat: repeat;
  border-radius: 25px 0px 0px 0px;

  /* top box shadow */
  box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

  .app_navbar-menu-container-items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    svg {
      width: 35px;
      height: 35px;
      color: rgb(178, 0, 0);
      margin: 0rem 1rem;
    }
  }
}
