


/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"); */

@import url('https://fonts.googleapis.com/css2?family=Nova+Round&family=Poppins&family=Righteous&family=Roboto&family=Russo+One&family=Squada+One&display=swap');

:root {
  --font-base: 'Poppins','Roboto','Nova Round','Squada One','Russo One','Righteous', sans-serif;;

  /* --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff; */

  --primary-color: rgb(255, 223, 16);
  /* --secondary-color: rgb(178, 0, 0); */
  --secondary-color: rgb(0, 0, 0);
  --black-color: #030303;
  --lightGray-color: #fffcf1;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}