.gallery__header {
  padding-top: 150px;

  @media screen and (max-width: 1000px) {
    padding-top: 100px;
  }
}

.gallery_tags {
  padding-top: 30px;
  display: flex;
  gap: 20px;
  justify-content: center;

  h4 {
    background-color: var(--secondary-color);
    padding: 7px 15px;
    color: white;
    border-radius: 7px;
    cursor: pointer;
  }
}

.gallery_container {
  position: relative;
}
.gallery-photo {
  margin: 30px 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    margin: 30px 20px;
  }
}

.gallery_photo-info {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 300px;
  padding: 10px;
  gap: 10px;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 90%;
    object-fit: cover;
    border-radius: 10px;

    @media screen and (max-width: 450px) {
      height: 80%;
    }
  }
}

/*Photo*/

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 10px;

  & :hover {
    opacity: 0.8;
  }
}

@media screen and (max-width: 990px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media screen and (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

/*Model*/

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}
.model svg {
  cursor: pointer;
  position: fixed;
  top: 20px;
  right: 20px;
}

/*Video*/

.gallery-video {
  margin: 30px 50px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    margin: 30px 20px;
  }
}

.gallery_video-info {
  img {
    border-radius: 10px;

    @media screen and (max-width: 600px) {
      width: 300px;
    }
  }
}

// .gallery_video-iframe{
//     display: flex;
//     width: 400px;
//     height: 290px;
//     justify-content: center;

//     iframe{
//         width: 100%;
//         border:none;
//         border-radius: 10px;
//     }

//       @media screen and (max-width:600px) {
//         width:300px;
//         height: 190px;
//       }
// }
