.clock_lazy {
  width: 270px;
  height: 50px;
  z-index: 1;
  position: absolute;
  opacity: 0;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  animation: lazyload_loading 1s ease-in-out;
  margin: auto;
  transform: translate(-8%, -0%);

  @media screen and (max-width: 1000px) {
    transform: translate(-25%, -0%);
  }
  @media screen and (max-width: 600px) {
    width: 260px;

    transform: translate(-25%, -0%);
  }

  p {
    font-size: 20px;
    color: rgb(0, 0, 0);
  }
}

@keyframes lazyload_loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes lazyload {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.clock__timer {
  position: relative;

  animation: lazyload 1.5s ease-in-out;

  display: flex;
  // background-color: bisque;

  justify-content: center;
  align-items: center;

  gap: 5px;

  .clock__timer-div {
    display: flex;
    justify-content: center;
    align-items: center;

    // width: 80px;
    height: 50px;
    // background-color: rgb(24, 23, 23);
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    // color: white;

    h3 {
      font-size: 35px;
      padding: 3px;
      color: #ffffff;
    }
  }

  span {
    font-size: 40px;
    color: rgb(0, 0, 0);
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -10px;
  }
}
