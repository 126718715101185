@keyframes crescendo {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.95);
  }
}

.whatsapp_model_container_none {
  display: none;
}

.whatsapp_container {
  position: relative;
  z-index: 15;
}

.whatsapp_cover {
  position: fixed;
  bottom: 60px;
  right: 10px;
  width: 150px;
  height: 45px;
  display: flex;
  background-color: rgb(9, 94, 84);
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  animation: crescendo 1.5s alternate infinite ease-in;

  p {
    letter-spacing: 0.9px;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  svg {
    @media screen and (max-width: 600px) {
      scale: 0.7;
    }
  }

  @media screen and (max-width: 600px) {
    width: 120px;
    height: 40px;
    gap: 2px;
  }

  &:hover {
    scale: 0.95;
  }
}

/*model*/
@keyframes weChat-animation {
  0% {
    opacity: 0;
  }
}

.whatsapp_model_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  background-color: rgb(230, 221, 212);
  height: 270px;
  padding-bottom: 10px;

  border-radius: 10px;
  position: fixed;
  bottom: 120px;
  right: 10px;
  animation-name: weChat-animation;
  animation-duration: 1s;

  // transition:  opacity .5s  ease-in-out ;

  /*Top*/

  .model_top_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      90deg,
      rgba(7, 94, 84, 1) 0%,
      rgba(18, 140, 126, 1) 85%
    );
    color: #ffffff;
    border-radius: 10px;

    .model_top_box_top {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      padding: 10px 10px;
      padding-bottom: 0px;
      gap: 10px;

      img {
        width: 30px;
        border-radius: 50%;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      svg {
        cursor: pointer;
      }
    }

    .model_top_box_bottom {
      display: flex;
      justify-content: center;

      p {
        font-size: 11px;
        padding-right: 12px;
        padding-bottom: 10px;
      }
    }
  }

  /*Middile*/

  .model_bottom_box {
    height: 120px;
    padding: 20px 10px;
    background-color: rgb(230, 221, 212);

    .model_bottom_box_message {
      padding: 0 10px;
      background-color: rgb(255, 255, 255);
      border-radius: 0 5px 5px;
      padding: 10px 10px;

      p {
        font-size: 14px;
      }
    }
  }

  /*Bottom_InputBox*/

  .model_bottom_input_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    input {
      width: 200px;
      height: 40px;
      border-radius: 10px;
      border: 0.5px solid;
      border-color: #a1a1a1;
      padding: 10px;
    }

    svg {
      color: rgba(18, 140, 126, 1);
    }
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;
    width: 270px;
    background-color: rgb(230, 221, 212);
    height: 240px;
    padding-bottom: 10px;
  }
}
