a {
  text-decoration: none;
  // color: inherit;
}

// .header2_container-main{
//     width: 100vw;
//     background-image: url(../../assets/Hero3.jpg);
// }

.header__main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  .header__upper {
    width: 100vw;
    background-image: url(../../assets/Hero3.jpg);
    background-repeat: no-repeat;
    height: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 105px;
    // padding-right: 30px;
    padding-bottom: 50px;

    @media screen and (max-width: 1300px) {
      padding: 50px;
      padding-top: 105px;
    }

    @media screen and (max-width: 1000px) {
      padding: 20px;
      padding-top: 40px;
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
      // align-items: flex-start;
      // align-items: center;
      justify-content: center;
      background-image: url(../../assets/Hero3.jpg);
    }

    .header__upper-right {
      width: 50%;

      .header__upper_right-child {
        display: flex;
        width: 100%;

        justify-content: flex-end;

        img {
          border-radius: 20px;
          width: 100%;

          object-fit: cover;
          box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);

          // @media screen and (min-width:1300px) {

          //    max-height: 600px;
          // }

          @media screen and (max-width: 700px) {
            margin-top: 40px;
          }

          @media screen and (max-width: 500px) {
            margin-top: 30px;
          }
        }

        @media screen and (max-width: 700px) {
          justify-content: center;
        }
      }

      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
  }

  .header__lower {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    // padding-left: 130px;
    // padding-right: 130px;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
    }

    @media screen and (max-width: 1300px) {
      // padding-left: 70px;
      // padding-right: 70px;
    }

    .header__lower-left {
      width: 500px;

      .header__lower__left-masters {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 270px;
        background-color: #e0e0e09d;

        border-radius: 30px;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;

        padding: 15px 20px;
        gap: 20px;
        box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);

        p {
          color: black;
          font-size: large;
        }

        h2 {
          // font-family: 'Nova Round';

          font-size: x-large;

          letter-spacing: 1.5px;
          // color: var(--primary-color);
          color: rgb(0, 0, 0);
        }

        .h4btn {
          // font-family: 'Nova Round';
          background-color: rgb(0, 0, 0);
          // color: white;
          width: 150px;
          height: 50px;
          padding: 10px;
          border-radius: 10px;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: space-between;
          box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);
          cursor: pointer;
          color: #ffffff;

          a {
            color: inherit;
            text-decoration: none;
          }
        }

        @media screen and (max-width: 1300px) {
          gap: 10px;
          height: 270px;

          h2 {
            letter-spacing: 1px;
            // font-size: large;
          }
        }

        @media screen and (max-width: 1000px) {
          p {
            line-height: 25px;
          }

          h2 {
            letter-spacing: 1.5px;
            font-size: large;
          }
        }

        @media screen and (max-width: 900px) {
          // h2 {
          //     // font-size: x-large;
          // }
        }

        @media screen and (max-width: 600px) {
          height: 350px;
        }
      }

      @media screen and (max-width: 600px) {
        width: 300px;
      }
    }

    .header__lower-center {
      width: 500px;

      .header__lower__left-timer {
        display: flex;
        width: 100%;
        height: 270px;
        background-color: #e0e0e09d;
        // background: linear-gradient(135deg,
        // #e6eaea,
        // #efefef);
        // background: linear-gradient(135deg,
        //         #5c28ea,
        //         #ff8b61);
        border-radius: 30px;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        // text-align: center;
        flex-direction: column;
        padding: 15px 20px;
        gap: 20px;
        box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);

        h2 {
          // font-family: 'Nova Round';
          text-align: center;
          font-size: x-large;
          letter-spacing: 1.5px;
          color: rgb(0, 0, 0);
          // text-transform: uppercase;

          @media screen and (max-width: 1000px) {
            letter-spacing: 1.5px;
            font-size: large;
          }
        }

        @media screen and (max-width: 900px) {
          // h2 {
          //     font-size: x-large;

          // }
        }

        .header__lower__left-clock {
          // width: 50%;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 1000px) {
            width: 150px;
          }

          @media screen and (max-width: 600px) {
            width: 50%;
          }
        }

        .clock_data {
          display: flex;
          // font-family: 'Nova Round';

          // justify-content: space-evenly;
          // align-items: center;
          text-align: center;
          gap: 22px;
          margin-top: -35px;
          color: rgb(0, 0, 0);
          letter-spacing: 1px;
          font-size: small;

          @media screen and (max-width: 600px) {
            gap: 24px;
          }
        }

        .h4btn {
          // font-family: 'Nova Round';
          background-color: rgb(0, 0, 0);
          // color: white;
          width: 150px;
          height: 50px;
          padding: 10px;
          border-radius: 10px;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: space-between;
          box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);
          cursor: pointer;
          color: #ffffff;

          a {
            color: inherit;
            text-decoration: none;
          }
        }

        @media screen and (max-width: 1300px) {
          gap: 10px;
          height: 270px;

          h2 {
            letter-spacing: 1px;
            // font-size: medium;
          }
        }

        @media screen and (max-width: 900px) {
          width: 100%;
        }

        @media screen and (max-width: 600px) {
          height: 320px;
        }
      }

      @media screen and (max-width: 600px) {
        width: 300px;
      }
    }

    .header__lower-right {
      width: 500px;

      .header__lower__left-fsj {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 270px;
        background-color: #e0e0e09d;

        border-radius: 30px;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;

        padding: 15px 20px;
        gap: 15px;
        box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);

        p {
          color: black;
          font-size: large;
        }

        h2 {
          font-size: x-large;
          letter-spacing: 1.5px;
          color: rgb(0, 0, 0);
        }

        .h4btn {
          background-color: rgb(0, 0, 0);
          // color: white;
          width: 150px;
          height: 50px;
          padding: 10px;
          border-radius: 10px;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: space-between;
          box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);
          cursor: pointer;
          color: #ffffff;

          a {
            // color: inherit;
            text-decoration: none;
          }
        }

        @media screen and (max-width: 1300px) {
          gap: 10px;
          height: 270px;

          h2 {
            letter-spacing: 1px;
            // font-size: medium;
          }
        }

        @media screen and (max-width: 1000px) {
          p {
            line-height: 25px;
          }

          h2 {
            letter-spacing: 1.5px;
            font-size: large;
          }
        }

        @media screen and (max-width: 900px) {
          // h2 {
          //     font-size: x-large;
          // }
        }

        @media screen and (max-width: 600px) {
          height: 350px;
        }
      }

      @media screen and (max-width: 600px) {
        width: 300px;
      }
    }
  }
}

.header__upper-left-updated {
  color: rgb(255, 255, 255);

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-family: Roboto;
    font-size: 30px;
    margin-bottom: 10px;

    @media screen and (max-width: 1300px) {
      font-size: 25px;
    }

    @media screen and (max-width: 1000px) {
      font-size: 20px;
    }
  }

  h2 {
    font-size: 40px;
    letter-spacing: 2px;
    font-family: Roboto;

    // text-shadow: 2px 2px #e1e1e1;
    @media screen and (max-width: 1300px) {
      font-size: 30px;
    }

    @media screen and (max-width: 1000px) {
      font-size: 22px;
    }
  }

  .header__upper-left-updated-build {
    background-color: #fff;
    width: 250px;
    border-radius: 10px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);

    h5 {
      color: #000;
      font-family: Roboto;
      font-size: 20px;

      @media screen and (max-width: 1300px) {
        font-size: 15px;
      }

      @media screen and (max-width: 1000px) {
        font-size: 13px;
      }
    }

    @media screen and (max-width: 1300px) {
      width: 200px;
    }

    @media screen and (max-width: 1000px) {
      width: 170px;
    }
  }

  .h4btn {
    // font-family: 'Nova Round';
    margin-top: 15px;
    background-color: rgb(255, 234, 0);
    // color: white;
    width: 180px;
    height: 50px;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    color: #000000;

    a {
      color: #000;
      text-decoration: none;
    }

    @media screen and (max-width: 700px) {
      margin-top: 45px;
    }
  }
}
