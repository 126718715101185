.menuItems-list {
  display: flex;

  margin: 0 1rem;
  cursor: pointer;
  font-size: 0.75rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  @media screen and (max-width: 1000px) {
    align-items: flex-start;
    line-height: 40px;

    font-size: 0.9rem;
  }
  @media screen and (min-width: 1500px) {
    font-size: 0.95rem;
  }

  @media screen and (max-width: 1200px) {
    margin: 0 0.4rem;
  }

  // div {
  //   width: 55px;
  //   height: 5px;
  //   background: transparent;
  //   border-radius: 10px;
  //   margin-bottom: 5px;
  //   @media screen and (max-width:1000px) {
  //     display: none;
  //   }
  // }

  nav,
  a {
    color: black;
    text-decoration: none;
    flex-direction: column;

    text-transform: uppercase;
    font-weight: 900;

    transition: all 0.3s ease-in-out;

    &:hover {
      padding: 5px;
      color: rgb(178, 0, 0);
      // background-color: rgb(0, 0, 0);

      border-radius: 5px;

      @media screen and (max-width: 1000px) {
        padding: 5px;
      }
    }
  }

  // a {

  //   color: black;
  //   text-decoration: none;
  //   flex-direction: column;

  //   text-transform: uppercase;
  //   font-weight: 900;

  //   transition: all 0.3s ease-in-out;

  //   &:hover {

  //     padding: 5px;
  //     color: rgb(255, 255, 255);
  //     background-color: rgb(0, 0, 0);

  //     border-radius: 5px;

  //     @media screen and (max-width:1000px) {
  //       padding: 5px;
  //     }

  //   }
  // }
}
