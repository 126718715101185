.aboutus__main {
  padding-top: 150px;

  @media screen and (max-width: 1000px) {
    padding-top: 100px;
  }
}

.aboutus__header {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--secondary-color);
}

.aboutus__about {
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.aboutus__desc {
  margin-top: 40px;
  padding-left: 50px;
  padding-right: 50px;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;

    text-justify: inter-word;
  }

  p {
    font-size: 1.2rem;
    line-height: 40px;
    // text-align: justify;
  }
}

.about__features {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  padding: 100px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    gap: 60px;
    padding: 30px;
  }

  .features__card {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 250px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;

    .card-icon {
      color: rgb(14, 8, 69);

      // background-color: bisque;
    }

    p {
      padding: 20px;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: rgb(3, 3, 3);
      letter-spacing: 1px;
    }
    &:hover {
      scale: 1.02;
    }
  }
}

.mission_main {
  display: flex;
  flex-wrap: wrap;
  margin: 50px;
  justify-content: space-around;
  align-items: center;
  gap: 100px;

  @media screen and (max-width: 600px) {
    margin: 35px;
  }

  .mission__card {
    display: flex;
    flex-direction: column;
    width: 700px;
    height: 250px;
    padding: 30px;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: 1px 1px 10px rgba($color: #8f8f8f, $alpha: 0.71);

    justify-content: center;
    // align-items: center;
    transition: 0.3s ease-in-out;

    @media screen and (max-width: 600px) {
      height: auto;
      text-align: center;
    }

    h2 {
      color: rgb(14, 8, 69);
      margin-bottom: 12px;
    }

    p {
      font-size: 1.2rem;
      // text-align: justify;
    }
    &:hover {
      scale: 1.02;
    }
  }
}

.faculties__main {
  display: flex;
  flex-wrap: wrap;
  margin: 50px;
  margin-top: 100px;
  justify-content: space-around;
  align-items: center;
  gap: 50px;

  @media screen and (max-width: 600px) {
    margin: 35px;
  }

  .faculties__main-header1 {
    width: 500px;
    text-align: center;

    @media screen and (max-width: 600px) {
      margin-top: 30px;
      // text-align: left;
      font-size: 0.8rem;
    }
  }

  .faculties__section1 {
    display: flex;

    padding-left: 50px;
    padding-right: 50px;
    gap: 50px;
    justify-content: space-evenly;
    // align-items: center;

    @media screen and (max-width: 1400px) {
      flex-wrap: wrap;
      padding-left: 20px;
      padding-right: 20px;
    }

    @media screen and (max-width: 1000px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .faculties-desc {
      width: 50%;

      @media screen and (max-width: 1300px) {
        width: 100%;
        // margin-bottom: -50px;
      }

      p {
        font-size: 1.2rem;
        // text-align: justify;
        line-height: 40px;
      }
    }

    .faculties__desc-img {
      img {
        width: 100%;
        height: 600px;
        border-radius: 20px;

        @media screen and (max-width: 600px) {
          height: auto;
        }
      }
    }
  }
}

.features__card-bottom {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 550px;
  background-color: #ffffff;
  border-radius: 10px;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 1px 1px 10px rgba($color: #8f8f8f, $alpha: 0.71);

  @media screen and (max-width: 600px) {
    height: 700px;
  }

  .card-icon {
    margin-top: 30px;

    color: rgb(14, 8, 69);

    // background-color: bisque;
  }

  p {
    padding: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(14, 8, 69);
    letter-spacing: 1px;
  }

  .faculties__online {
    margin: 20px;
    border-radius: 5px;
    background-color: rgb(241, 241, 241);

    p {
      color: #000;
      font-size: 1.1rem;
      line-height: 30px;
      // text-align: justify;

      @media screen and (max-width: 600px) {
        font-size: 1rem;
      }
    }
  }
}

.team__header {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
}

.app__profile {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 40px;
  margin-bottom: 70px;
}

.app__profiles-item {
  background: linear-gradient(135deg, #eeeeee, #fffbfb);
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);
  // padding: 30px;
  border-radius: 10px;

  width: 250px;
  height: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 2rem;
  overflow: hidden;
  text-align: center;

  h4 {
    margin-top: 7px;
  }

  p {
    font-size: 1.1rem;
    padding: 10px 15px;
    // font-family: Roboto;
  }

  img {
    width: 100%;
    height: 250px;
    border-radius: 15px;
    object-fit: cover;
    transition: 0.2s ease-in-out;

    &:hover {
      scale: 1.05;
    }
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}
