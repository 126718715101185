.error_main-container {
  padding: 20px;
  padding-top: 110px;
  background-color: rgba(255, 166, 0, 0.1);
  display: flex;
  flex-direction: column;

  align-items: center;

  h1 {
    font-size: 120px;
    color: rgba(255, 68, 0, 0.8);
  }
  h3 {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.8);
  }
  h4 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
  }
  p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
  }

  .error-image {
    width: 450px;
    img {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      width: auto;
    }
  }
  button {
    border: none;
    background-color: rgb(255, 234, 0);
    width: 180px;
    height: 45px;
    border-radius: 10px;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    font-size: 18px;
    transition: 0.2s ease-in;

    &:hover {
      transform: scale(1.01);
      background-color: rgb(0, 115, 255);
      color: #ffffff;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 50px;
  }
}
