.fsj__header {
  padding-top: 150px;

  @media screen and (max-width: 1000px) {
    padding-top: 100px;
  }
}

.fsj__container {
  display: flex;
  margin: 50px 150px;
  gap: 50px;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 1400px) {
    margin: 50px;
    gap: 50px;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.fsj__container-info {
  display: flex;
  flex-direction: column;

  line-height: 40px;
  // text-align: justify;
  gap: 20px;

  @media screen and (max-width: 1300px) {
    .info__till-1300px {
      display: none;
    }
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.fsj__container-image {
  // width: 600px;
  justify-content: center;
  padding-top: 72px;
  text-align: center;

  @media screen and (max-width: 1000px) {
    padding-top: 0px;
    // width: 500px;
    display: flex;
    margin: auto;
  }

  img {
    width: 600px;
    // width: 100%;
    border-radius: 15px;

    @media screen and (max-width: 1400px) {
      width: 500px;
    }
    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }
}

.info__from-1300px {
  margin: 50px;
  line-height: 40px;
  // text-align: justify;

  @media screen and (min-width: 1300px) {
    display: none;
  }
}

.ausbilding_container {
  display: flex;
  flex-direction: column;
  margin: 50px 150px;
  line-height: 40px;
  // text-align: justify;
  gap: 20px;
  @media screen and (max-width: 1400px) {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
  }
  .aus_image {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 500px;
      height: 350px;
      object-fit: cover;
      border-radius: 15px;

      @media screen and (max-width: 640px) {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// .aus_docs{

// //    background-color: black;
//    width: 300px;

//     cursor: pointer;

//     a{
//         display: flex;
//         text-decoration: none;
//         align-items: center;
//         gap :10px;
//         color: #000;
//     }
//     :hover{
//         color: var(--secondary-color);
//     }
// }
