.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

// .about__core__features{
//  text-align: center;
//  font-size: x-large;
//  margin-top: 30px;
//  margin-bottom: -20px;

//  color: var(--black-color);
// }

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  background: linear-gradient(135deg, #eeeeee, #fffbfb);
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);
  // padding: 30px;
  border-radius: 10px;

  width: 300px;
  height: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 2rem;

  @media screen and (max-width: 768px) {
    margin: 1rem;
  }

  h2 {
    // font-family: Roboto;
    font-size: large;
    text-align: center;
  }

  p {
    font-size: 1.1rem;
    padding: 10px 15px;
    // font-family: Roboto;
  }

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}

.achivements__header {
  color: var(--secondary-color);
  margin-top: 70px;
  text-align: center;
}

.achivements__blocks {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  justify-content: center;
  // width: 500px;

  .blocks__item {
    gap: 20px;
    padding: 20px;
    width: 500px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px;

    @media screen and (max-width: 600px) {
      width: 300px;
      height: auto;
    }

    .blocks-icon {
      color: rgb(14, 8, 69);
      @media screen and (max-width: 600px) {
        margin-top: 10px;
      }
    }

    .blocks-content {
      color: #000000;

      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 30px;

      h3 {
        font-size: 1.4rem;
        font-weight: 900;
      }
      p {
        margin-top: 10px;
        font-size: 1rem;
      }
    }
    transition: 0.5s ease-in-out;

    &:hover {
      scale: 1.02;
    }
  }
}
