.accomodation-container {
  padding-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  .accommodation-images {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    // align-items: center;
    justify-content: center;

    img {
      width: 500px;
      // max-height: 400px;
      border-radius: 5px;

      @media screen and (max-width: 768px) {
        width: 85vw;
      }
    }
  }

  p {
    padding: 100px;
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and (max-width: 1000px) {
      padding: 0px;
      padding-bottom: 20px;
    }
  }

  @media screen and (max-width: 1000px) {
    padding: 20px;
    padding-bottom: 0;
    padding-top: 0;
    // justify-content: center;
    align-items: center;
  }

  button {
    margin: 15px;
    margin-bottom: 25px;
    border: none;
    background-color: rgb(255, 234, 0);
    // color: white;
    width: 180px;
    height: 50px;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    color: #000000;
    font-size: 18px;
    // transform: scale(1);
    transition: 0.2s ease-in;

    &:hover {
      transform: scale(1.01);
      background-color: rgb(0, 115, 255);
      color: #ffffff;
    }
  }
}
