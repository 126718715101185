.faq_header {
  padding: 150px 20px 20px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 450px) {
    gap: 10px;
  }

  @media screen and (max-width: 1000px) {
    padding-top: 100px;
  }
}
